.serached-item-sec { max-width:100%;}
.serached-item-sec .card { border:0; border-bottom:1px solid rgba(0,0,0,.125); margin:15px 0;padding-bottom:10px;}
.serached-item-sec  .card img { max-width:100px;border:1px solid rgba(0,0,0,.125); background:#fff;padding:2px;}
.serached-item-sec a{ color:#0069d9;}

ul.list-group li { text-align: left }
ul.list-group li img { width: 50px; border-radius: 50%; height: 50px; margin: 0px 15px 0px 0px; }
.jobs_footer{background:#F6F7F8;padding:10px 0px 10px; text-align:center;position: fixed;width:100%;bottom:0px;font-size:12px;color:#B4B4B4; margin-top:50px;}
ul.user_policy {margin: 0;padding: 0;}
.user_policy li {list-style: none;display: inline-block;}
.jobs_footer span, .user_info li a, .user_policy li a {color: rgb(103 99 99)!important;}
.user_policy li a {color: #b4b4b4; margin: 5px; display: block;}