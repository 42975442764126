ul.aboutmenu li a {
    color: #9b9b9b;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    display: block;
    font-weight: 500;
}

ul.aboutmenu li {
    list-style: none;
    display: inline-block;
    line-height: 48px;
    margin-right: 15px;
}

ul.aboutmenu li a {
    color: #9b9b9b;
    font-size: 14px;
    position: relative;
    text-decoration: none;
    display: block;
    font-weight: 500;
}

ul.aboutmenu li a#downarrow:before {
    content: '\276E';
	font-size: 14px;
    display: inline-block;
    transform: rotate(270deg);
    padding: 0px 10px;
    float: right;
}

.float-left{
    border:none;
    border:0px;
    margin:0px;
    padding:0px;
    font: 67.5% "Lucida Sans Unicode", "Bitstream Vera Sans", "Trebuchet Unicode MS", "Lucida Grande", Verdana, Helvetica, sans-serif;
    font-size:18px;
    font-weight:bold;
}
.float-left ul{
    height:35px;
    list-style:none;
    margin:0;
    padding:0;
}
.float-left li{
    float:left;
    padding:0px;
	margin-right: 0px !important;
}
.float-left li a{
    /* background:#006633 url("../images/seperator.gif") bottom right no-repeat; */
    color:#ffffff;
    display:block;
    font-weight:normal;
    line-height:35px;
    margin:0px;
    padding: 0px 15px;
    text-align:center;
    text-decoration:none;
}
.float-left li a:hover, .float-left ul li:hover a{
    /* background: #003f20 url("../images/hover.gif") bottom center no-repeat; */
    color:#9b9b9b;
    text-decoration:none;
}
.float-left li ul{
    display:none;
    height:auto;
    padding:0px;
    margin:0px;
    border:0px;
    position:absolute;
    width:225px;
    z-index:99999;
	background: #fff;
    /*top:1em;
    /*left:0;*/
}
.float-left li:hover ul{
    display:block;
}
.float-left li li {
    /* background:url('../images/sub_sep.gif') bottom left no-repeat; */
    display:block;
    float:none;
    margin:0px;
    padding:0px;
    width:225px;
}
.float-left li:hover li a{
    background:none;
}
.float-left li ul a{
    display:block;
    height:30px;
    font-size:12px;
    font-style:normal;
	margin: 10px 0px;
    padding:0px 10px 0px 15px;
    text-align:left;
	/* color: #464646; */
    /* background-color: #fff; */
    /* border-left: 3px solid #3fb0f6; */
}
.float-left li ul a:hover, .float-left li ul li:hover a{
    /* background:#003f20 url('../images/hover_sub.gif') center left no-repeat; */
    border:0px;
    /* color:#9b9b9b; */
    text-decoration:none;
	padding: 0px 10px 0px 12px;
	color: #464646;
    background-color: #fff;
    border-left: 3px solid #3fb0f6;
}
.float-left p{
    clear:left;
}